var render = function () {
  var _vm$content$shortDesc;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('div', {
    staticClass: "mb-6 flex items-center gap-4"
  }, [_c('ArrowForward', {
    staticClass: "cursor-pointer",
    attrs: {
      "direction": "left"
    },
    on: {
      "handleClick": function handleClick() {
        _vm.$router.push('/kelas');
      }
    }
  }), _c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Bilingual Content")])], 1), _c('div', {
    staticClass: "flex gap-2 ml-5"
  }, _vm._l(_vm.tabOptions, function (tab, indexTab) {
    return _c('div', {
      key: tab.code + indexTab,
      staticClass: "tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer",
      class: [_vm.selectedLanguange === tab.code ? 'font-bold bg-white' : 'bg-neutral-50'],
      on: {
        "click": function click($event) {
          return _vm.selectTab(tab.code);
        }
      }
    }, [_vm._v(" " + _vm._s(tab.label) + " ")]);
  }), 0), _c('div', {
    staticClass: "shadow-small rounded-lg bg-white px-6 pb-6"
  }, [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "flex justify-end py-4"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Save ".concat(_vm.selectedLanguange === 'en' ? 'English' : 'Bahasa Indonesia')
    },
    on: {
      "action": function action($event) {
        return _vm.save();
      }
    }
  })], 1), _c('div', {
    staticClass: "grid grid-cols-2 gap-4"
  }, [_c('div', {
    staticClass: "LEFT-CONTENT flex flex-col gap-4"
  }, [_c('ExpandableContent', {
    attrs: {
      "title": "Hero Section",
      "expandContentIsOpen": ""
    }
  }, [_c('template', {
    slot: "dropdown-content"
  }, [_c('div', {
    staticClass: "flex flex-col gap-4"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Category",
      "borderEnabled": true,
      "placeholder": "Enter category"
    },
    model: {
      value: _vm.content.categoryText,
      callback: function callback($$v) {
        _vm.$set(_vm.content, "categoryText", $$v);
      },
      expression: "content.categoryText"
    }
  }), _c('div', [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Hero Description")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.content.shortDescriptionText,
      expression: "content.shortDescriptionText"
    }],
    attrs: {
      "maxlength": "200"
    },
    domProps: {
      "value": _vm.content.shortDescriptionText
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.content, "shortDescriptionText", $event.target.value);
      }
    }
  }), _c('p', {
    staticClass: "text-xs float-right"
  }, [_vm._v(_vm._s(((_vm$content$shortDesc = _vm.content.shortDescriptionText) === null || _vm$content$shortDesc === void 0 ? void 0 : _vm$content$shortDesc.length) || 0) + " / 200")])])], 1), _c('div', {
    staticClass: "grid grid-cols-2 gap-4 py-2"
  }, [_c('div', {
    staticClass: "flex flex-col gap-4"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": _vm.layoutTypeSelected === 'EVENT_LAYOUT' ? 'Day' : 'Class Duration',
      "borderEnabled": true,
      "notes": _vm.layoutTypeSelected === 'EVENT_LAYOUT' ? 'E.g. Saturday, Monday' : 'Provide info in weeks ( e.g 16 weeks )',
      "placeholder": "Enter class duration"
    },
    model: {
      value: _vm.content.durationText,
      callback: function callback($$v) {
        _vm.$set(_vm.content, "durationText", $$v);
      },
      expression: "content.durationText"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Tech Stack",
      "borderEnabled": true,
      "placeholder": "Enter tech stack",
      "notes": "Tech stack/software used in class"
    },
    model: {
      value: _vm.content.techStacksText,
      callback: function callback($$v) {
        _vm.$set(_vm.content, "techStacksText", $$v);
      },
      expression: "content.techStacksText"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Location",
      "borderEnabled": true,
      "placeholder": "Select location"
    },
    model: {
      value: _vm.content.location,
      callback: function callback($$v) {
        _vm.$set(_vm.content, "location", $$v);
      },
      expression: "content.location"
    }
  })], 1), _c('div', {
    staticClass: "flex flex-col gap-4"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": _vm.layoutTypeSelected === 'EVENT_LAYOUT' ? 'Time' : 'Weekly Schedule',
      "borderEnabled": true,
      "placeholder": "Enter weekly schedule",
      "notes": _vm.layoutTypeSelected === 'EVENT_LAYOUT' ? 'E.g. 17.00 - 19.00 WIB' : 'E.g. Mon, Wed (17.00 - 19.00 WIB)'
    },
    model: {
      value: _vm.content.schedule,
      callback: function callback($$v) {
        _vm.$set(_vm.content, "schedule", $$v);
      },
      expression: "content.schedule"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Benefits",
      "borderEnabled": true,
      "placeholder": "Enter benefits",
      "notes": "E.g. Certificate of Competence"
    },
    model: {
      value: _vm.content.benefitText,
      callback: function callback($$v) {
        _vm.$set(_vm.content, "benefitText", $$v);
      },
      expression: "content.benefitText"
    }
  })], 1)])])], 2), _c('ExpandableContent', {
    attrs: {
      "title": "Class Description",
      "expandContentIsOpen": ""
    }
  }, [_c('template', {
    slot: "dropdown-content"
  }, [_c('div', {
    staticClass: "flex flex-col gap-6 pt-2 pb-6"
  }, [_c('div', {}, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Description")]), _c('quill-editor', {
    ref: "descQuillEditor",
    attrs: {
      "options": _vm.quillOptions
    },
    model: {
      value: _vm.content.description,
      callback: function callback($$v) {
        _vm.$set(_vm.content, "description", $$v);
      },
      expression: "content.description"
    }
  })], 1)])])], 2), _c('ExpandableContent', {
    attrs: {
      "title": "Class Outcome",
      "expandContentIsOpen": ""
    }
  }, [_c('template', {
    slot: "dropdown-content"
  }, [_c('div', {
    staticClass: "flex flex-col gap-4 py-2"
  }, [_c('div', {
    staticClass: "rounded-lg border border-yellow border-dashed"
  }, [_c('p', {
    staticClass: "p-3 text-yellow text-xs"
  }, [_vm._v("If one of the fields is empty, system will hide this section on the Class Detail page")])]), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Benefits Title",
      "borderEnabled": true,
      "placeholder": "Input benefits title"
    },
    model: {
      value: _vm.content.coreClassOutCome[0].benefitTitle,
      callback: function callback($$v) {
        _vm.$set(_vm.content.coreClassOutCome[0], "benefitTitle", $$v);
      },
      expression: "content.coreClassOutCome[0].benefitTitle"
    }
  }), _c('div', {
    staticClass: "rounded-lg bg-neutral-100 w-full p-5"
  }, [_c('h3', {
    staticClass: "text-xs text-neutral-500 font-semibold"
  }, [_vm._v("Class Outcome (max 6)")]), _c('div', {
    staticClass: "flex flex-col gap-4 pt-3"
  }, [_vm._l(_vm.content.coreClassOutCome[0].outComesName, function (outcome, outcomeIdx) {
    return _c('div', {
      key: 'classOutcome' + outcomeIdx
    }, [_c('div', {
      staticClass: "flex items-center gap-3"
    }, [_c('TextField', {
      staticClass: "w-full",
      attrs: {
        "type": "text",
        "notes": "E.g. Software Developer, Formal english proficiency",
        "label": "Outcome " + (outcomeIdx + 1),
        "borderEnabled": true,
        "placeholder": "Input expected role/improved skills",
        "maxlength": "30",
        "maxCharacter": "30"
      },
      model: {
        value: _vm.content.coreClassOutCome[0].outComesName[outcomeIdx],
        callback: function callback($$v) {
          _vm.$set(_vm.content.coreClassOutCome[0].outComesName, outcomeIdx, $$v);
        },
        expression: "content.coreClassOutCome[0].outComesName[outcomeIdx]"
      }
    }), _c('Button', {
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Trash');
        }
      },
      on: {
        "action": function action($event) {
          return _vm.deleteItem(_vm.content.coreClassOutCome[0].outComesName, outcomeIdx);
        }
      }
    })], 1)]);
  }), _c('div', [_c('Button', {
    attrs: {
      "buttonText": "Add Class Outcome",
      "disabled": _vm.isClassOutcomeIncludeEmptyContent || _vm.content.coreClassOutCome[0].outComesName.length >= 6,
      "size": "regular",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.addItem(_vm.content.coreClassOutCome[0].outComesName, '');
      }
    }
  })], 1)], 2)]), _c('div', {
    staticClass: "rounded-lg bg-neutral-100 w-full p-5"
  }, [_c('h3', {
    staticClass: "text-xs text-neutral-500 font-semibold"
  }, [_vm._v("Benefits (max 4)")]), _c('div', {
    staticClass: "flex flex-col gap-4 pt-3"
  }, [_vm._l(_vm.content.coreClassOutCome[0].benefitItems, function (benefit, benefitsIdx) {
    return _c('div', {
      key: 'benefits' + benefitsIdx
    }, [_c('div', {
      staticClass: "flex items-center gap-3"
    }, [_c('TextField', {
      staticClass: "w-full",
      attrs: {
        "type": "text",
        "notes": "E.g. High income",
        "label": "Benefit " + (benefitsIdx + 1),
        "borderEnabled": true,
        "placeholder": "Input class outcome",
        "maxlength": "60",
        "maxCharacter": "60"
      },
      model: {
        value: _vm.content.coreClassOutCome[0].benefitItems[benefitsIdx],
        callback: function callback($$v) {
          _vm.$set(_vm.content.coreClassOutCome[0].benefitItems, benefitsIdx, $$v);
        },
        expression: "content.coreClassOutCome[0].benefitItems[benefitsIdx]"
      }
    }), _c('Button', {
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Trash');
        }
      },
      on: {
        "action": function action($event) {
          return _vm.deleteItem(_vm.content.coreClassOutCome[0].benefitItems, benefitsIdx);
        }
      }
    })], 1)]);
  }), _c('div', [_c('Button', {
    attrs: {
      "buttonText": "Add Benefit",
      "size": "regular",
      "disabled": _vm.isClassBenefitsIncluedEmptyContent || _vm.content.coreClassOutCome[0].benefitItems.length >= 4,
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.addItem(_vm.content.coreClassOutCome[0].benefitItems, '');
      }
    }
  })], 1)], 2)])], 1)])], 2), _c('ExpandableContent', {
    attrs: {
      "title": "Class Syllabus",
      "expandContentIsOpen": ""
    }
  }, [_c('template', {
    slot: "dropdown-content"
  }, [_c('div', {
    staticClass: "flex flex-col gap-4 py-2"
  }, [_c('div', {
    staticClass: "rounded-lg border flex flex-col gap-4 border-neutral-250 p-5"
  }, [_c('h3', {
    staticClass: "text-xs text-neutral-500 font-semibold"
  }, [_vm._v("Syllabus Items")]), _vm._l(_vm.content.sylabusClasses, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "p-4 bg-neutral-100 rounded-lg"
    }, [_c('div', {
      staticClass: "flex justify-between items-start"
    }, [_c('p', {
      staticClass: "text-sm font-bold"
    }, [_vm._v("Syllabus Item " + _vm._s(idx + 1))]), _c('Button', {
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Trash');
        }
      },
      on: {
        "action": function action($event) {
          return _vm.deleteItem(_vm.content.sylabusClasses, idx);
        }
      }
    })], 1), _c('div', {
      staticClass: "w-full mb-5"
    }, [_c('TextField', {
      attrs: {
        "type": "text",
        "label": "Syllabus Title",
        "notes": "E.g. Learn Product Development Framework, Basic Java",
        "borderEnabled": "",
        "placeholder": "Enter title"
      },
      model: {
        value: item.title,
        callback: function callback($$v) {
          _vm.$set(item, "title", $$v);
        },
        expression: "item.title"
      }
    })], 1), _c('div', {
      staticClass: "mb-2"
    }, [_c('label', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("Syllabus Description")]), _c('div', {
      staticClass: "bg-white"
    }, [_c('quill-editor', {
      ref: "myQuillEditor",
      refInFor: true,
      attrs: {
        "options": _vm.quillOptions
      },
      model: {
        value: item.description,
        callback: function callback($$v) {
          _vm.$set(item, "description", $$v);
        },
        expression: "item.description"
      }
    })], 1)])]);
  }), _c('div', {
    staticClass: "flex"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Add Syllabus Item",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.addMoreSyllabusClasses();
      }
    }
  })], 1)], 2)])])], 2)], 1), _c('div', {
    staticClass: "RIGHT-CONTENT flex flex-col gap-4"
  }, [_c('ExpandableContent', {
    attrs: {
      "title": "Class Stages (max 4)",
      "expandContentIsOpen": ""
    }
  }, [_c('template', {
    slot: "dropdown-content"
  }, [_c('div', {
    staticClass: "py-2 flex flex-col gap-4"
  }, [_vm._l(_vm.content.coreClassStage, function (stage, stageIndex) {
    return _c('div', {
      key: "classStages" + stageIndex,
      staticClass: "flex flex-col gap-3 rounded-lg bg-neutral-100 w-full p-5"
    }, [_c('div', {
      staticClass: "flex justify-between items-center"
    }, [_c('h3', {
      staticClass: "text-xs text-neutral-500 font-semibold"
    }, [_vm._v("Stage " + _vm._s(stageIndex + 1))]), _c('Button', {
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Trash');
        }
      },
      on: {
        "action": function action($event) {
          return _vm.deleteItem(_vm.content.coreClassStage, stageIndex);
        }
      }
    })], 1), _c('TextField', {
      staticClass: "w-full",
      attrs: {
        "type": "text",
        "notes": "E.g. Admission Process / Interview, Final Project",
        "label": "Stage Title",
        "borderEnabled": true,
        "placeholder": "Enter Stage Title"
      },
      model: {
        value: _vm.content.coreClassStage[stageIndex].title,
        callback: function callback($$v) {
          _vm.$set(_vm.content.coreClassStage[stageIndex], "title", $$v);
        },
        expression: "content.coreClassStage[stageIndex].title"
      }
    }), _c('div', [_c('label', {
      staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
    }, [_vm._v("Description")]), _c('textarea', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.content.coreClassStage[stageIndex].description,
        expression: "content.coreClassStage[stageIndex].description"
      }],
      domProps: {
        "value": _vm.content.coreClassStage[stageIndex].description
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) { return; }

          _vm.$set(_vm.content.coreClassStage[stageIndex], "description", $event.target.value);
        }
      }
    })])], 1);
  }), _c('div', [_c('Button', {
    attrs: {
      "buttonText": "Add Class Stage",
      "disabled": _vm.isClassStagesIncludeEmptyContent || _vm.content.coreClassStage.length >= 4,
      "size": "regular",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.addItem(_vm.content.coreClassStage, {
          title: '',
          description: '',
          seqNo: _vm.content.coreClassStage.length + 1
        });
      }
    }
  })], 1)], 2)])], 2), _c('ExpandableContent', {
    attrs: {
      "title": "Class FAQ (max 9)",
      "expandContentIsOpen": ""
    }
  }, [_c('template', {
    slot: "dropdown-content"
  }, [_c('div', {
    staticClass: "py-2 flex flex-col gap-4"
  }, [_vm._l(_vm.content.coreClassFaqs, function (faq, faqIndex) {
    return _c('div', {
      key: "coreClassFaqs" + faqIndex,
      staticClass: "flex flex-col gap-3 rounded-lg bg-neutral-100 w-full p-5"
    }, [_c('div', {
      staticClass: "flex justify-between items-center"
    }, [_c('h3', {
      staticClass: "text-xs text-neutral-500 font-semibold"
    }, [_vm._v("FAQ " + _vm._s(faqIndex + 1))]), _c('Button', {
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Trash');
        }
      },
      on: {
        "action": function action($event) {
          return _vm.deleteItem(_vm.content.coreClassFaqs, faqIndex);
        }
      }
    })], 1), _c('TextField', {
      staticClass: "w-full",
      attrs: {
        "type": "text",
        "notes": "E.g. Admission Process / Interview, Final Project",
        "label": "Question",
        "borderEnabled": true,
        "placeholder": "Enter Question"
      },
      model: {
        value: _vm.content.coreClassFaqs[faqIndex].question,
        callback: function callback($$v) {
          _vm.$set(_vm.content.coreClassFaqs[faqIndex], "question", $$v);
        },
        expression: "content.coreClassFaqs[faqIndex].question"
      }
    }), _c('div', [_c('label', {
      staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
    }, [_vm._v("Answer")]), _c('textarea', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.content.coreClassFaqs[faqIndex].answer,
        expression: "content.coreClassFaqs[faqIndex].answer"
      }],
      domProps: {
        "value": _vm.content.coreClassFaqs[faqIndex].answer
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) { return; }

          _vm.$set(_vm.content.coreClassFaqs[faqIndex], "answer", $event.target.value);
        }
      }
    })])], 1);
  }), _c('div', [_c('Button', {
    attrs: {
      "buttonText": "Add FAQ",
      "disabled": _vm.isClassFaqIncludeEmptyContent || _vm.content.coreClassFaqs.length >= 9,
      "size": "regular",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.addItem(_vm.content.coreClassFaqs, {
          question: '',
          answer: '',
          seqNo: _vm.content.coreClassFaqs.length + 1
        });
      }
    }
  })], 1)], 2)])], 2)], 1)])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }