<template>
  <div>
    <div>
      <div class="mb-6 flex items-center gap-4">
        <ArrowForward
          direction="left"
          @handleClick="
            () => {
              $router.push('/kelas')
            }
          "
          class="cursor-pointer"
        />
        <h1 class="text-3xl font-bold">Bilingual Content</h1>
      </div>

      <div class="flex gap-2 ml-5">
        <div
          v-for="(tab, indexTab) in tabOptions"
          :key="tab.code + indexTab"
          @click="selectTab(tab.code)"
          class="tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer"
          :class="[selectedLanguange === tab.code ? 'font-bold bg-white' : 'bg-neutral-50']"
        >
          {{ tab.label }}
        </div>
      </div>

      <div class="shadow-small rounded-lg bg-white px-6 pb-6">
        <div v-if="stillLoading" class="loading-page">
          <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
        </div>
        <div v-else>
          <div class="flex justify-end py-4">
            <Button :buttonText="`Save ${selectedLanguange === 'en' ? 'English' : 'Bahasa Indonesia'}`" @action="save()" />
          </div>
          <div class="grid grid-cols-2 gap-4">
            <div class="LEFT-CONTENT flex flex-col gap-4">
              <ExpandableContent title="Hero Section" expandContentIsOpen>
                <template slot="dropdown-content">
                  <div class="flex flex-col gap-4">
                    <TextField type="text" label="Category" :borderEnabled="true" placeholder="Enter category" v-model="content.categoryText" />
                    <div>
                      <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Hero Description</label>
                      <textarea v-model="content.shortDescriptionText" maxlength="200"></textarea>
                      <p class="text-xs float-right">{{ content.shortDescriptionText?.length || 0 }} / 200</p>
                    </div>
                  </div>
                  <div class="grid grid-cols-2 gap-4 py-2">
                    <div class="flex flex-col gap-4">
                      <TextField
                        type="text"
                        class=""
                        :label="layoutTypeSelected === 'EVENT_LAYOUT' ? 'Day' : 'Class Duration'"
                        :borderEnabled="true"
                        :notes="layoutTypeSelected === 'EVENT_LAYOUT' ? 'E.g. Saturday, Monday' : 'Provide info in weeks ( e.g 16 weeks )'"
                        placeholder="Enter class duration"
                        v-model="content.durationText"
                      />
                      <TextField type="text" label="Tech Stack" :borderEnabled="true" placeholder="Enter tech stack" notes="Tech stack/software used in class" v-model="content.techStacksText" />
                      <TextField type="text" label="Location" :borderEnabled="true" placeholder="Select location" v-model="content.location" />
                      <!-- <Dropdown :options="locationOptions" label="Location" v-model="content.location" :default="content.location" placeholder="Select location" /> -->
                    </div>
                    <div class="flex flex-col gap-4">
                      <TextField
                        type="text"
                        :label="layoutTypeSelected === 'EVENT_LAYOUT' ? 'Time' : 'Weekly Schedule'"
                        :borderEnabled="true"
                        placeholder="Enter weekly schedule"
                        :notes="layoutTypeSelected === 'EVENT_LAYOUT' ? 'E.g. 17.00 - 19.00 WIB' : 'E.g. Mon, Wed (17.00 - 19.00 WIB)'"
                        v-model="content.schedule"
                      />
                      <TextField type="text" label="Benefits" :borderEnabled="true" placeholder="Enter benefits" notes="E.g. Certificate of Competence" v-model="content.benefitText" />
                    </div>
                  </div>
                </template>
              </ExpandableContent>
              <ExpandableContent title="Class Description" expandContentIsOpen>
                <template slot="dropdown-content">
                  <div class="flex flex-col gap-6 pt-2 pb-6">
                    <div class="">
                      <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Description</label>
                      <quill-editor ref="descQuillEditor" v-model="content.description" :options="quillOptions" />
                    </div>
                    <!-- <div class="flex flex-col gap-6" v-if="programSelected === 'Prakerja'">
                    <div>
                      <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Class Value</label>
                      <quill-editor ref="descQuillEditor" v-model="payload.classValue" :options="quillOptions" />
                    </div>
                    <div class="">
                      <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Precondition</label>
                      <quill-editor ref="descQuillEditor" v-model="payload.prasyarat" :options="quillOptions" />
                    </div>
                    <div class="">
                      <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Sasaran Peserta</label>
                      <quill-editor ref="descQuillEditor" v-model="payload.targetParticipant" :options="quillOptions" />
                    </div>
                    <div>
                      <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Aspek Kompetensi Pengetahuan</label>
                      <quill-editor ref="descQuillEditor" v-model="payload.knowledgeCompetence" :options="quillOptions" />
                    </div>
                    <div>
                      <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Aspek Kompetensi Sikap</label>
                      <quill-editor ref="descQuillEditor" v-model="payload.attitudeCompetence" :options="quillOptions" />
                    </div>
                    <div class="">
                      <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Metode Ajar/Latih dan Evaluasi Peserta</label>
                      <quill-editor ref="descQuillEditor" v-model="payload.learningMethod" :options="quillOptions" />
                    </div>
                    <div class="">
                      <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Notes</label>
                      <quill-editor ref="descQuillEditor" v-model="payload.note" :options="quillOptions" />
                    </div>
                    <div>
                      <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Tujuan Pelatihan</label>
                      <quill-editor ref="descQuillEditor" v-model="payload.purpose" :options="quillOptions" />
                    </div>
                    <div class="">
                      <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Aktivitas Pelatihan</label>
                      <quill-editor ref="descQuillEditor" v-model="payload.activity" :options="quillOptions" />
                    </div>
                    <div class="">
                      <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Aspek Kompetensi Keterampilan</label>
                      <quill-editor ref="descQuillEditor" v-model="payload.skillCompetence" :options="quillOptions" />
                    </div>
                    <div class="">
                      <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Kapasitas Jenis / Kelas Pelatihan</label>
                      <quill-editor ref="descQuillEditor" v-model="payload.capacity" :options="quillOptions" />
                    </div>
                    <div class="">
                      <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Jenis Klarifikasi Sertifikat</label>
                      <quill-editor ref="descQuillEditor" v-model="payload.certificateClarification" :options="quillOptions" />
                    </div>
                  </div> -->
                  </div>
                </template>
              </ExpandableContent>
              <ExpandableContent title="Class Outcome" expandContentIsOpen>
                <template slot="dropdown-content">
                  <div class="flex flex-col gap-4 py-2">
                    <div class="rounded-lg border border-yellow border-dashed">
                      <p class="p-3 text-yellow text-xs">If one of the fields is empty, system will hide this section on the Class Detail page</p>
                    </div>
                    <TextField type="text" label="Benefits Title" :borderEnabled="true" placeholder="Input benefits title" v-model="content.coreClassOutCome[0].benefitTitle" />
                    <div class="rounded-lg bg-neutral-100 w-full p-5">
                      <h3 class="text-xs text-neutral-500 font-semibold">Class Outcome (max 6)</h3>
                      <div class="flex flex-col gap-4 pt-3">
                        <div class="" v-for="(outcome, outcomeIdx) in content.coreClassOutCome[0].outComesName" :key="'classOutcome' + outcomeIdx">
                          <div class="flex items-center gap-3">
                            <TextField
                              type="text"
                              notes="E.g. Software Developer, Formal english proficiency"
                              :label="`Outcome ` + (outcomeIdx + 1)"
                              :borderEnabled="true"
                              placeholder="Input expected role/improved skills"
                              v-model="content.coreClassOutCome[0].outComesName[outcomeIdx]"
                              maxlength="30"
                              maxCharacter="30"
                              class="w-full"
                            />
                            <Button
                              type="secondary"
                              size="icon"
                              :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')"
                              @action="deleteItem(content.coreClassOutCome[0].outComesName, outcomeIdx)"
                            />
                          </div>
                        </div>
                        <div>
                          <Button
                            buttonText="Add Class Outcome"
                            :disabled="isClassOutcomeIncludeEmptyContent || content.coreClassOutCome[0].outComesName.length >= 6"
                            size="regular"
                            type="tertiary"
                            @action="addItem(content.coreClassOutCome[0].outComesName, '')"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="rounded-lg bg-neutral-100 w-full p-5">
                      <h3 class="text-xs text-neutral-500 font-semibold">Benefits (max 4)</h3>
                      <div class="flex flex-col gap-4 pt-3">
                        <div class="" v-for="(benefit, benefitsIdx) in content.coreClassOutCome[0].benefitItems" :key="'benefits' + benefitsIdx">
                          <div class="flex items-center gap-3">
                            <TextField
                              type="text"
                              notes="E.g. High income"
                              :label="`Benefit ` + (benefitsIdx + 1)"
                              :borderEnabled="true"
                              placeholder="Input class outcome"
                              v-model="content.coreClassOutCome[0].benefitItems[benefitsIdx]"
                              maxlength="60"
                              maxCharacter="60"
                              class="w-full"
                            />
                            <Button
                              type="secondary"
                              size="icon"
                              :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')"
                              @action="deleteItem(content.coreClassOutCome[0].benefitItems, benefitsIdx)"
                            />
                          </div>
                        </div>
                        <div>
                          <Button
                            buttonText="Add Benefit"
                            size="regular"
                            :disabled="isClassBenefitsIncluedEmptyContent || content.coreClassOutCome[0].benefitItems.length >= 4"
                            type="tertiary"
                            @action="addItem(content.coreClassOutCome[0].benefitItems, '')"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </ExpandableContent>
              <ExpandableContent title="Class Syllabus" expandContentIsOpen>
                <template slot="dropdown-content">
                  <div class="flex flex-col gap-4 py-2">
                    <div class="rounded-lg border flex flex-col gap-4 border-neutral-250 p-5">
                      <h3 class="text-xs text-neutral-500 font-semibold">Syllabus Items</h3>
                      <div class="p-4 bg-neutral-100 rounded-lg" v-for="(item, idx) in content.sylabusClasses" :key="idx">
                        <div class="flex justify-between items-start">
                          <p class="text-sm font-bold">Syllabus Item {{ idx + 1 }}</p>
                          <Button type="secondary" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteItem(content.sylabusClasses, idx)" />
                        </div>
                        <div class="w-full mb-5">
                          <TextField type="text" label="Syllabus Title" v-model="item.title" notes="E.g. Learn Product Development Framework, Basic Java" borderEnabled placeholder="Enter title" />
                        </div>
                        <div class="mb-2">
                          <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Syllabus Description</label>
                          <div class="bg-white">
                            <quill-editor ref="myQuillEditor" v-model="item.description" :options="quillOptions" />
                          </div>
                        </div>
                      </div>
                      <div class="flex">
                        <Button buttonText="Add Syllabus Item" type="tertiary" @action="addMoreSyllabusClasses()" />
                      </div>
                    </div>
                  </div>
                </template>
              </ExpandableContent>
            </div>
            <div class="RIGHT-CONTENT flex flex-col gap-4">
              <ExpandableContent title="Class Stages (max 4)" expandContentIsOpen>
                <template slot="dropdown-content">
                  <div class="py-2 flex flex-col gap-4">
                    <div class="flex flex-col gap-3 rounded-lg bg-neutral-100 w-full p-5" v-for="(stage, stageIndex) in content.coreClassStage" :key="`classStages` + stageIndex">
                      <div class="flex justify-between items-center">
                        <h3 class="text-xs text-neutral-500 font-semibold">Stage {{ stageIndex + 1 }}</h3>
                        <Button type="secondary" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteItem(content.coreClassStage, stageIndex)" />
                      </div>
                      <TextField
                        type="text"
                        notes="E.g. Admission Process / Interview, Final Project"
                        label="Stage Title"
                        :borderEnabled="true"
                        placeholder="Enter Stage Title"
                        v-model="content.coreClassStage[stageIndex].title"
                        class="w-full"
                      />
                      <div>
                        <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Description</label>
                        <textarea v-model="content.coreClassStage[stageIndex].description"></textarea>
                      </div>
                    </div>
                    <div>
                      <Button
                        buttonText="Add Class Stage"
                        :disabled="isClassStagesIncludeEmptyContent || content.coreClassStage.length >= 4"
                        size="regular"
                        type="tertiary"
                        @action="addItem(content.coreClassStage, { title: '', description: '', seqNo: content.coreClassStage.length + 1 })"
                      />
                    </div>
                  </div>
                </template>
              </ExpandableContent>
              <ExpandableContent title="Class FAQ (max 9)" expandContentIsOpen>
                <template slot="dropdown-content">
                  <div class="py-2 flex flex-col gap-4">
                    <div class="flex flex-col gap-3 rounded-lg bg-neutral-100 w-full p-5" v-for="(faq, faqIndex) in content.coreClassFaqs" :key="`coreClassFaqs` + faqIndex">
                      <div class="flex justify-between items-center">
                        <h3 class="text-xs text-neutral-500 font-semibold">FAQ {{ faqIndex + 1 }}</h3>
                        <Button type="secondary" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteItem(content.coreClassFaqs, faqIndex)" />
                      </div>
                      <TextField
                        type="text"
                        notes="E.g. Admission Process / Interview, Final Project"
                        label="Question"
                        :borderEnabled="true"
                        placeholder="Enter Question"
                        v-model="content.coreClassFaqs[faqIndex].question"
                        class="w-full"
                      />
                      <div>
                        <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Answer</label>
                        <textarea v-model="content.coreClassFaqs[faqIndex].answer"></textarea>
                      </div>
                    </div>
                    <div>
                      <Button
                        buttonText="Add FAQ"
                        :disabled="isClassFaqIncludeEmptyContent || content.coreClassFaqs.length >= 9"
                        size="regular"
                        type="tertiary"
                        @action="addItem(content.coreClassFaqs, { question: '', answer: '', seqNo: content.coreClassFaqs.length + 1 })"
                      />
                    </div>
                  </div>
                </template>
              </ExpandableContent>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { showVueToast } from '@/utils'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/ArrowForward'),
    Label: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Label'),
    Chevron: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Chevron'),
    Attach: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Attach'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    TextArea: () => import(/* webpackChunkName: "TextArea" */ '@/components/Form/TextArea'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    CurrencyInput: () => import(/* webpackChunkName: "CurrencyInput" */ '@/components/Form/CurrencyInput'),
    ExpandableContent: () => import(/* webpackChunkName: "ExpandableContent" */ '@/components/Expandable/ExpandableContent'),
    Calendar: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Calendar')
  },
  data() {
    return {
      stillLoading: false,
      content: {
        languageId: 1,
        language: 'EN',
        description: '',
        durationText: '',
        shortDescriptionText: '',
        benefitText: '',
        categoryText: '',
        location: 'Online',
        schedule: '',
        coreClassStage: [],
        coreClassFaqs: [],
        coreClassOutCome: [
          {
            outComesName: [],
            benefitTitle: '',
            benefitItems: []
          }
        ],
        sylabusClasses: []
      },
      coreClassData: null,
      tabOptions: [
        {
          label: 'English',
          code: 'en'
        },
        {
          label: 'Bahasa Indonesia',
          code: 'id'
        }
      ],
      selectedLanguange: 'en',
      locationOptions: ['Online', 'Offline'],
      quillOptions: {
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], [{ size: ['small', false, 'large', 'huge'] }], ['link'], ['clean']]
        }
      },
      //////////DELETE SOON//////////
      columns: [
        { label: 'Class Schedule', field: 'nama' },
        { label: 'Batch', field: 'batch' },
        { label: 'Participants', field: 'jumlah_peserta' },
        { label: 'Start Time', field: 'start_date' }
      ],
      program: { code: 'Prakerja', name: 'Prakerja' },
      archived: false,
      informationOnly: false,
      autoExpandActivity: true,
      autoCreateLmsClass: false,
      requireAdministration: false,
      requirePmoRedeem: false,
      code: '',
      name: '',
      types: [],
      maxParticipants: '0',
      minParticipants: '0',
      description: '',
      videos: [],
      quizes: [],
      instructors: [],
      conditions: [],
      error: [],
      listQuiz: [],
      listInstructor: [],
      listClass: [],
      programOptions: [],
      layoutOptions: [
        {
          name: 'Event',
          value: 'EVENT_LAYOUT'
        },
        {
          name: 'Class',
          value: 'CLASS_LAYOUT'
        },
        {
          name: 'Class + Schedule',
          value: 'CLASS_SCHEDULE_LAYOUT'
        },
        {
          name: 'Collaboration',
          value: 'COLLABORATION'
        }
      ],
      listTimeParameter: [
        { id: 'YEAR', name: 'Year(s)' },
        { id: 'MONTH', name: 'Month(s)' },
        { id: 'DAY', name: 'Day(s)' }
      ],
      generalParams: { limit: 600, sort: '' },
      selectedTab: 'General',
      quillOptions: {
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], [{ size: ['small', false, 'large', 'huge'] }], ['link'], ['clean']]
        }
      },
      scheduledPrices: [],
      listPromoType: [
        {
          name: 'Lifetime',
          code: 'LIFETIME'
        },
        {
          name: 'Scheduled Promo',
          code: 'SCHEDULEDPROMO'
        },
        {
          name: 'Class Schedule’s D-Day',
          code: 'CLASSSCHEDULESDDAY'
        }
      ],
      payload: {
        name: '',
        location: '',
        type: '',
        price: 0,
        slug: '',
        schedule: '',
        scheduleFrom: '',
        scheduleTo: '',
        durationText: '',
        shortDescriptionText: '',
        techStacksText: '',
        benefitText: '',
        categoryText: '',
        classValue: '',
        description: '',
        prasyarat: '',
        note: '',
        customContent: '',
        url1: '',
        url2: '',
        knowledgeCompetence: '',
        skillCompetence: '',
        attitudeCompetence: '',
        targetParticipant: '',
        purpose: '',
        capacity: '',
        learningMethod: '',
        certificateClarification: '',
        activity: '',
        layoutType: {
          name: 'Class',
          value: 'CLASS_LAYOUT'
        }
      },
      syllabusDocument: '',
      // syllabusItems: [],
      historyAlumniClasses: [],
      certificateCompetence: [],
      certificates: [],
      certificateTypeOptions: [],
      certificateTemplateOptions: [],
      certificateTemplateOptionsId: '',
      currentSchedulePage: 0,
      schedulePageSize: 10,
      selectedClass: null,
      selectedInstructor: null,
      selectedIndexToBeDeleted: null,
      selectedTypeToBeDeleted: null,
      refresh: false,
      visibleDeleteModal: false,
      isActivitiesAccord: true,
      activities: [],
      coreClassPaymentMethod: [],
      paymentMethodSelected: [],
      coreClassSoftwareTool: [],
      layoutTypeSelected: 'CLASS'
    }
  },
  created() {
    this.getClassDetails()
  },
  computed: {
    ...mapGetters('common', ['pageLoading']),
    coreClassId() {
      return this.$route.params.id
    },
    customerId() {
      return localStorage.getItem('client')
    },
    isClassStagesIncludeEmptyContent() {
      const classStagesContent = this.content?.coreClassStage.filter((stage) => stage?.title && stage?.description)
      const result = classStagesContent.length === this.content?.coreClassStage.length ? false : true
      return result
    },
    isClassOutcomeIncludeEmptyContent() {
      const copyData = JSON.parse(JSON.stringify(this.content?.coreClassOutCome[0]?.outComesName))
      if (typeof copyData === 'object') {
        const classOutcomeContent = copyData.filter((item) => item)
        const result = classOutcomeContent.length === copyData.length ? false : true
        return result
      }
      return false
    },
    isClassBenefitsIncluedEmptyContent() {
      const classBenefits = this.content?.coreClassOutCome[0]?.benefitItems?.filter((item) => item)
      const result = classBenefits.length === this.content?.coreClassOutCome[0]?.benefitItems?.length ? false : true
      return result
    },
    isClassFaqIncludeEmptyContent() {
      const classFaqs = this.content?.coreClassFaqs.filter((stage) => stage?.answer && stage?.question)
      const result = classFaqs.length === this.content?.coreClassFaqs.length ? false : true
      return result
    }
  },
  methods: {
    ...mapActions('kelas', ['GET_KELAS', 'UPDATE_KELAS']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    addItem(objContent, item) {
      objContent.push(item)
    },
    deleteItem(objContent, idx) {
      objContent.splice(idx, 1)
    },
    addMoreSyllabusClasses() {
      const obj = this.content.sylabusClasses
      let lastObj = {
        seqNo: 0
      }
      if (obj.length > 0) {
        lastObj = obj[obj.length - 1]
      }
      const item = {
        courseKit: '',
        description: '',
        name: '',
        seqNo: lastObj.seqNo + 1,
        title: ''
      }
      obj.push(item)
    },
    selectTab(value) {
      this.selectedLanguange = value
      this.getClassDetails()
    },
    readFileNameFromAWSLink(link) {
      if (link) return link.substring(66, link.length)
      return ''
    },
    save() {
      this.showLoading()
      this.content.coreClassOutCome = this.content.coreClassOutCome.map((item) => {
        if (item?.outComesName.length > 0) {
          item.outComesName = item.outComesName.join()
        } else {
          item.outComesName = ''
        }
        return {
          ...item
        }
      })

      const preentryCondition = JSON.stringify({ and: this.conditions })
      const prices = JSON.parse(JSON.stringify(this.scheduledPrices))
      const pricesList = prices.map((item) => {
        return {
          ...item,
          startDate: item.startDate ? moment(item.startDate).format('YYYY-MM-DDTHH:mm:ss+0700') : '',
          endDate: item.endDate ? moment(item.endDate).format('YYYY-MM-DDTHH:mm:ss+0700') : '',
          price: isNaN(item.price) ? '' : parseInt(item.price),
          scheduleType: item.scheduleType?.code,
          dayBeforeSchedule: item.dayBeforeSchedule?.value ? item.dayBeforeSchedule?.value : 0
        }
      })
      const formattedSlug = this.payload.slug ? this.payload.slug.toLowerCase().trim() : ''
      let fixArrayActivities = []
      for (let i = 0; i < this.activities.length; i++) {
        fixArrayActivities.push({
          seqNo: i + 1,
          title: this.activities[i].title,
          isIndependent: this.activities[i].isIndependent,
          coreClassActivityItem: []
        })
        for (let j = 0; j < this.activities[i].activityItems.length; j++) {
          fixArrayActivities[i].coreClassActivityItem.push({
            ...this.activities[i].activityItems[j],
            seqNo: j + 1
          })
        }
      }

      const constructedPayload = {
        coreClassActivity: fixArrayActivities,
        autoCreateLmsClass: this.autoCreateLmsClass,
        requireAdministration: this.requireAdministration,
        archived: this.archived,
        code: this.code,
        nama: this.name,
        max_peserta: this.maxParticipants,
        min_peserta: this.minParticipants,
        java_instructur_id_array: this.instructors,
        quiz_exercise_id_array: this.quizes.filter((quiz) => quiz),
        video_url_array: [],
        tugas_description: this.description,
        competences: this.certificateCompetence,
        deliveryTypes: this.types,
        program: this.program,
        preentryCondition,
        classValue: this.payload.classValue,
        description: this.payload.description,
        historyAlumniClasses: this.historyAlumniClasses.map((testimony, testimonyIdx) => {
          return {
            ...testimony,
            testimonyId: testimony.id,
            seqNo: testimonyIdx + 1
          }
        }),
        scheduledPrices: pricesList,
        note: this.payload.note,
        prasyarat: this.payload.prasyarat,
        price: isNaN(this.payload.price) ? '' : parseInt(this.payload.price),
        schedule: this.payload.schedule,
        scheduleFrom: this.payload.scheduleFrom,
        scheduleTo: this.payload.scheduleTo,
        location: this.payload.location,
        seqNo: 0,
        slug: formattedSlug,
        durationText: this.payload.durationText,
        shortDescriptionText: this.payload.shortDescriptionText,
        techStacksText: this.payload.techStacksText,
        benefitText: this.payload.benefitText,
        categoryText: this.payload.categoryText,
        syllabus: this.syllabusDocument,
        // sylabusClasses: this.syllabusItems,
        url1: this.payload.url1,
        url2: this.payload.url2,
        customContent: this.payload.customContent,
        knowledgeCompetence: this.payload.knowledgeCompetence,
        skillCompetence: this.payload.skillCompetence,
        attitudeCompetence: this.payload.attitudeCompetence,
        targetParticipant: this.payload.targetParticipant,
        purpose: this.payload.purpose,
        capacity: this.payload.capacity,
        learningMethod: this.payload.learningMethod,
        certificateClarification: this.payload.certificateClarification,
        activity: this.payload.activity,
        layoutType: this.payload.layoutType.value,
        certificateCompetence: this.certificateCompetence,
        certificates: this.certificates,
        autoExpandActivity: this.autoExpandActivity,
        informationOnly: this.informationOnly,
        requirePmoRedeem: this.requirePmoRedeem,
        coreClassPaymentMethod: this.coreClassPaymentMethod,
        coreClassSoftwareTool: this.coreClassSoftwareTool
          .filter((tool) => tool.softwareToolUrl)
          .map((tool) => {
            delete tool.softwareToolFileName
            return { ...tool }
          }),
        content: [this.content]
      }

      this.UPDATE_KELAS({
        masterId: this.coreClassId,
        customerId: this.customerId,
        params: {
          lang: this.selectedLanguange
        },
        payload: constructedPayload
      })
        .then((res) => {
          if (res.data.code === 200) {
            showVueToast(`Content saved successfully!`, 'success', 2000)
            this.getClassDetails()
          }
        })
        .catch(() => {})
    },
    getClassDetails() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.stillLoading = true
      this.showLoading()
      const params = {
        lang: this.selectedLanguange
      }
      this.GET_KELAS({
        customerId: this.customerId,
        masterId: this.coreClassId,
        params
      })
        .then((res) => {
          this.layoutTypeSelected = res.data.data.layoutType
          this.hideLoading()
          if (res.data.code === 200) {
            const contentLang = JSON.parse(JSON.stringify(res.data.data.content))
            this.content = null
            const coreClassData = JSON.parse(JSON.stringify(res.data.data))
            if (contentLang.length > 0) {
              const contructData = contentLang.map((item) => {
                if (item.coreClassOutCome.length === 0) {
                  item.coreClassOutCome.push({
                    outComesName: [],
                    benefitTitle: '',
                    benefitItems: []
                  })
                } else {
                  item.coreClassOutCome.map((item) => {
                    item.outComesName = item?.outComesName.split(',') || []
                    item.benefitTitle = item?.benefitTitle || ''
                    item.benefitItems = item?.benefitItems || []
                    return {
                      ...item
                    }
                  })
                }
                return {
                  ...item
                }
              })
              this.content = contructData[0]
            } else {
              this.content = {
                languageId: 2,
                language: 'ID',
                description: '',
                durationText: '',
                shortDescriptionText: '',
                benefitText: '',
                categoryText: '',
                location: 'Online',
                schedule: '',
                coreClassStage: [],
                coreClassFaqs: [],
                coreClassOutCome: [
                  {
                    outComesName: [],
                    benefitTitle: '',
                    benefitItems: []
                  }
                ],
                sylabusClasses: []
              }
            }
            this.program = coreClassData?.program
            this.autoCreateLmsClass = coreClassData?.autoCreateLmsClass
            this.requireAdministration = coreClassData.requireAdministration
            this.requirePmoRedeem = coreClassData.requirePmoRedeem
            this.autoExpandActivity = coreClassData.autoExpandActivity
            this.informationOnly = coreClassData.informationOnly
            this.archived = coreClassData.archived
            this.code = coreClassData.code
            this.name = coreClassData.nama
            this.maxParticipants = coreClassData.max_peserta
            this.minParticipants = coreClassData.min_peserta
            this.quizes = []
            for (let i = 0; i < coreClassData.quiz_exercise_id_array.length; i++) {
              this.quizes.push(coreClassData.quiz_exercise_id_array[i])
            }
            this.videos = []
            for (let j = 0; j < coreClassData.video_url_array.length; j++) {
              this.videos.push(coreClassData.video_url_array[j])
            }
            this.description = coreClassData.tugas_description
            this.instructors = []
            for (let k = 0; k < coreClassData.java_instructur_id_array.length; k++) {
              this.instructors.push(coreClassData.java_instructur_id_array[k])
            }
            this.conditions = coreClassData.preentryCondition ? JSON.parse(coreClassData.preentryCondition).and : []
            this.types = coreClassData.deliveryTypes
            this.syllabusDocument = JSON.parse(JSON.stringify(coreClassData?.syllabus || ''))
            // this.syllabusItems = JSON.parse(JSON.stringify(coreClassData?.sylabusClasses))
            const testimoni = coreClassData.historyAlumniClasses && JSON.parse(JSON.stringify(coreClassData.historyAlumniClasses))
            this.historyAlumniClasses = testimoni.map((item, idx) => {
              return { ...item.testimony, seqNo: idx + 1, testimonyId: item.testimony.id }
            })
            const schedulePriceTemp = coreClassData.scheduledPrices && JSON.parse(JSON.stringify(coreClassData.scheduledPrices))
            const listPromo = this.listPromoType
            const prices = schedulePriceTemp.map((item) => {
              return {
                ...item,
                startDate: item.scheduleType === 'LIFETIME' || item.scheduleType === 'CLASSSCHEDULESDDAY' ? '' : moment(item.startDate).format('YYYY-MM-DDTHH:mm'),
                endDate: item.scheduleType === 'LIFETIME' || item.scheduleType === 'CLASSSCHEDULESDDAY' ? '' : moment(item.endDate).format('YYYY-MM-DDTHH:mm'),
                price: typeof item.price === 'undefined' ? '' : item.price,
                scheduleType: listPromo.find((items) => items.code == item.scheduleType),
                dayBeforeSchedule:
                  item.scheduleType === 'LIFETIME' || item.scheduleType === 'SCHEDULEDPROMO'
                    ? {
                        name: '' + 'days before',
                        value: item.dayBeforeSchedule
                      }
                    : {
                        name: item.dayBeforeSchedule === 1 ? item.dayBeforeSchedule + ' day before' : item.dayBeforeSchedule + ' days before',
                        value: item.dayBeforeSchedule
                      }
              }
            })
            this.scheduledPrices = prices

            this.coreClassPaymentMethod = JSON.parse(JSON.stringify(coreClassData.coreClassPaymentMethod))

            this.coreClassSoftwareTool = JSON.parse(JSON.stringify(coreClassData.coreClassSoftwareTool)).map((tool) => {
              return {
                ...tool,
                softwareToolFileName: this.readFileNameFromAWSLink(tool.softwareToolUrl)
              }
            })

            const layoutType = this.layoutOptions.filter((item) => item.value === coreClassData.layoutType)[0]
            const certifCompetence = coreClassData.competences && JSON.parse(JSON.stringify(coreClassData.competences))
            const certificate = coreClassData.certificates && JSON.parse(JSON.stringify(coreClassData.certificates))
            certificate.map((item) => {
              const templateName = this.certificateTemplateOptions.filter((x) => x.id == item.certificate_template_id)[0]?.name
              item.certificateId = item.id
              item.certificateTemplate = { name: templateName, code: templateName }
              item.certificateType = { name: item.certificate_type, code: item.certificate_type }
              return item
            })
            this.certificateCompetence = certifCompetence
            this.certificates = certificate
            this.payload = {
              location: coreClassData.location,
              price: typeof coreClassData.price === 'undefined' ? '' : coreClassData.price,
              slug: coreClassData.slug,
              schedule: coreClassData.schedule,
              scheduleFrom: coreClassData.scheduleFrom,
              scheduleTo: coreClassData.scheduleTo,
              durationText: coreClassData.durationText,
              shortDescriptionText: coreClassData.shortDescriptionText,
              techStacksText: coreClassData.techStacksText,
              benefitText: coreClassData.benefitText,
              categoryText: coreClassData.categoryText,
              classValue: coreClassData.classValue,
              description: coreClassData.description,
              prasyarat: coreClassData.prasyarat,
              note: coreClassData.note,
              customContent: coreClassData.customContent,
              url1: coreClassData.url1,
              url2: coreClassData.url2,
              knowledgeCompetence: coreClassData.knowledgeCompetence,
              skillCompetence: coreClassData.skillCompetence,
              attitudeCompetence: coreClassData.attitudeCompetence,
              targetParticipant: coreClassData.targetParticipant,
              purpose: coreClassData.purpose,
              capacity: coreClassData.capacity,
              learningMethod: coreClassData.learningMethod,
              certificateClarification: coreClassData.certificateClarification,
              activity: coreClassData.activity,
              layoutType: layoutType
            }

            this.activities = []
            for (let l = 0; l < coreClassData.coreClassActivity.length; l++) {
              this.activities.push({
                title: coreClassData.coreClassActivity[l].title,
                isIndependent: coreClassData.coreClassActivity[l].isIndependent,
                activityItems: []
              })
              for (let m = 0; m < coreClassData.coreClassActivity[l].coreClassActivityItem.length; m++) {
                this.activities[l].activityItems.push({
                  title: coreClassData.coreClassActivity[l].coreClassActivityItem[m].title ? coreClassData.coreClassActivity[l].coreClassActivityItem[m].title : null,
                  description: coreClassData.coreClassActivity[l].coreClassActivityItem[m].description ? coreClassData.coreClassActivity[l].coreClassActivityItem[m].description : null,
                  activityItemType: coreClassData.coreClassActivity[l].coreClassActivityItem[m].activityItemType ? coreClassData.coreClassActivity[l].coreClassActivityItem[m].activityItemType : null,
                  itemReferenceId: coreClassData.coreClassActivity[l].coreClassActivityItem[m].itemReferenceId ? coreClassData.coreClassActivity[l].coreClassActivityItem[m].itemReferenceId : null,
                  includeInCertificate: coreClassData.coreClassActivity[l].coreClassActivityItem[m].includeInCertificate
                    ? coreClassData.coreClassActivity[l].coreClassActivityItem[m].includeInCertificate
                    : false,
                  download_status: coreClassData.coreClassActivity[l].coreClassActivityItem[m].download_status ? coreClassData.coreClassActivity[l].coreClassActivityItem[m].download_status : false,
                  warned: coreClassData.coreClassActivity[l].coreClassActivityItem[m].warned ? coreClassData.coreClassActivity[l].coreClassActivityItem[m].warned : false
                })
              }
            }
            this.stillLoading = false
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
textarea {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 12px;
  line-height: normal;
  font-size: 14px;
  resize: none;
  height: 120px;
  overflow: auto;
  outline: none;
}

.date {
  &::v-deep .vdatetime-input {
    padding: 12px 44px 12px 12px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid #d6d6d6;
    font-size: 16px;
    cursor: pointer;
    // pr-3 py-3 focus:outline-none focus:ring focus:ring-tosca w-full rounded-lg text-base
  }
}
</style>